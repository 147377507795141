<template>
<div class='app-container'>
  <div class="img-style">
    <img src="../../assets/404.png" alt="404" style='max-width:100%;margin: auto;display: block;' >
  </div>
</div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
};
</script>
<style scoped>
.app-container {
    width: 80%;
    margin: 120px auto;
  }

  .img-style {
    margin:0 auto;
    position: relative;
  }
</style>